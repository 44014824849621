import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import * as messageConstants from "../../utils/Messages";
import { niceNumberDecimalDisplay } from "../../utils/Util";

const listingStatusDetails = {
  PENDING: {
    text: "Pending",
    value: "PENDING",
    classname: "danger",
  },
  PROCESSING: {
    text: "Processing",
    value: "PROCESSING",
    classname: "info",
  },
  APPROVED: {
    text: "Approved",
    value: "APPROVED",
    classname: "success",
  },
  REJECTED: {
    text: "Rejected",
    value: "REJECTED",
    classname: "danger",
  },
};

class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.LISTINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    let authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("supplier/listings", {
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.listings,
          totalSize: response.data.totalListings,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const { data, sizePerPage, page, renderFlag } = this.state;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { whiteSpace: "nowrap" },
        dataField: "listingId",
        text: "#ID",
      },
      {
        headerClasses: "text-bold",
        dataField: "location",
        text: "Location",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var location = cell !== "" && cell !== null ? cell : "N/A";
          return <div>{location}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "dailyProductionMcf",
        text: "Daily Production (MCF)",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return <div className="text-center">{cell}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "initialEstimatedPowerCapacity",
        text: "Total Estimated Power Capacity (kWh)",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          // Fix up old data as required.
          let initialCap = row.initialEstimatedPowerCapacity;
          if (!row.initialEstimatedPowerCapacity ||
              row.initialEstimatedPowerCapacity == 0.0) {
            initialCap = row.estimatedPoweCapacity;
          }
          return (
            <div className="text-center">
              {niceNumberDecimalDisplay(initialCap, 4)} {row.totalProbablePossibleReservesUnit}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "estimatedPoweCapacity",
        text: "Remaining (unsold) Power Capacity (kWh)",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-center">
              {niceNumberDecimalDisplay(cell, 4)} {row.totalProbablePossibleReservesUnit}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "powerPrice",
        text: "Pricing (DEC/kWh)",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let decPrice = 0.0;
          // Handle legacy case of USD price set and incomplete listing
          // Legacy is fixed at the start DEC price of 0.05 DEC/$
          if (row.powerPrice) {
            if (row.powerPriceUnits &&
                row.powerPriceUnits == "dec") {
              decPrice = row.powerPrice;
            } else {
              // Legacy, fixed DEC price
              decPrice = Math.ceil(row.powerPrice / 0.05);
            }
          } else {
            decPrice = "unset";
          }
          return <div className="text-center">{decPrice}</div>;
          // TODO(rlgeiger): set cell tooltip for USD equivalent
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          const currentStatusDetails = listingStatusDetails[cell];
          return (
            <div>
              <div>
                <span className={"badge"}>{currentStatusDetails.value}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var date = moment(cell).format("DD/MM/YYYY HH:mm");
          return <div>{date}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let redirectLink = "/campaign_detail/" + row._id;
          return (
            <div className="text-left">
              <div className="d-inline-block">
                <div className="btn-group mr-1 mb-1">
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="btn default-border-btn dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdownMenuButton6"
                    type="button"
                  >
                    Action
                  </button>
                  <div
                    aria-labelledby="dropdownMenuButton6"
                    className="dropdown-menu"
                  >
                    <Link to={redirectLink}>
                      <button className="mr-2 mb-2 dropdown-item" type="button">
                        Details
                      </button>
                    </Link>
                    {row.status === "APPROVED" && (
                      <Link to={`/progress_reports/${row._id}`}>
                        <button
                          className="mr-2 mb-2 dropdown-item"
                          type="button"
                        >
                          Progress Reports
                        </button>
                      </Link>
                    )}
                    {row.status !== "APPROVED" && (
                      <Link to={`/campaign/edit/${row._id}`}>
                        <button
                          className="mr-2 mb-2 dropdown-item"
                          type="button"
                        >
                          Edit
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div>
              <div className="element-box">
                <div className="d-flex campaigns-heading">
                  <div className="text-sm h5">
                    <span>Campaigns</span>
                  </div>
                  <div className="btn-campaigns">
                    <Link to="/emd" className="btn default-btn">
                      Select Dealers{" "}
                      <i className="fa fa-info-circle" id="selectEmdInfo"></i>
                    </Link>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target="selectEmdInfo"
                    >
                      <PopoverBody>
                        If you wish to opt-in for your own Digital Security
                        offering make sure to find your registered Exempt Market
                        Dealer(EMD) who will assist you in the onboarding
                        process. If you wish to learn more about digital
                        securities, make sure to check out the Resources section
                        to access our educational content.
                      </PopoverBody>
                    </UncontrolledPopover>
                    <Link to="/campaign/new" className="btn default-btn ml-2">
                      Add Campaign
                    </Link>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Listings;
